//----------  Vertical / horizontal alignment
//------------------------------------------------------------------------------
@mixin align($position: relative, $direction: x) {
	position: $position;
	@if ($direction == 'y') {
		top: 50%;
		transform: translateY(-50%);
	} @else if ($direction == 'xy') {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else {
		left: 50%;
		transform: translateX(-50%);
	}
}

// example: @include align(absolute, xy); for centering both axis'
// default @include align(); will be relative on x axis

//	Visually hidden mixin for accessibility
@mixin visually-hidden() {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

// Hover mixin only for desktop devices
// to include use @include hover {your hover styles} can also include elements
@mixin hover {
	@media only screen and (min-width: 1025px) {
		.no-touch &:hover {
			@content;
		}
	}
}
