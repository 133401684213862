//---------- filters
//------------------------------------------------------------------------------

.filters__container {
	width: 100%;
	position: relative;
}

.filters__options {
	margin-right: 140px;
	@include media-breakpoint-down(lg) {
		margin-right: 0;
	}
}

.filters__dropdowns {
	vertical-align: top;
	display: inline-block;
	@include media-breakpoint-between(md, lg) {
		width: 100%;
		margin-bottom: 20px;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}

.filters__dropdown {
	width: 202px;
	padding-right: 22px;
	display: inline-block;
	@include media-breakpoint-between(md, lg) {
		padding-right: 0;
		padding-left: 22px;
		width: 25.9%;
		&:first-child {
			padding-left: 0;
		}
	}
	@include media-breakpoint-down(sm) {
		width: 53.5%;
		margin-bottom: 20px;
		&:last-child {
			width: 46.5%;
			padding-right: 0;
		}
	}
}

.filters__dropdown--large {
	width: 306px;
	@include media-breakpoint-between(md, lg) {
		width: 48.2%;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
		padding-right: 0;
	}
}

.btn-clear-filters {
	min-width: 182px;
	vertical-align: top;
	@include media-breakpoint-down(lg) {
		min-width: 156px;
	}
}

.layout__switches {
	position: absolute;
	top: 0;
	right: 0;
	@include media-breakpoint-between(md, lg) {
		top: 65px;
	}
	@include media-breakpoint-down(sm) {
		top: auto;
		bottom: -2px;
	}
}

.layout__switch {
	font-size: 40px;
	color: $gold;
	opacity: 0.25;
	transition: opacity $duration $easing;
	@include hover {
		opacity: 0.75;
	}
	@include media-breakpoint-down(lg) {
		font-size: 30px;
	}
}

.layout__switch--active {
	opacity: 1;
}

.layout__switch-list {
	margin-left: 35px;
	@include media-breakpoint-down(lg) {
		margin-left: 14px;
	}
}

// Template specific filters
.news-filters {
	margin-bottom: 82px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 70px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 50px;
	}
}

.project-filters {
	padding-top: 50px;
	margin-bottom: 80px;
	.module-title {
		margin-bottom: 38px;
	}
	@include media-breakpoint-between(md, lg) {
		padding-top: 20px;
		margin-bottom: 50px;
		.filters__dropdown--large {
			&:last-child {
				width: 51.8%;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: 18px;
		margin-bottom: 50px;
		.module-title {
			margin-bottom: 28px;
		}
		.filters__dropdown--large {
			&:last-child {
				width: 100%;
			}
		}
	}
}
