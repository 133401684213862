//---------- pagination
//------------------------------------------------------------------------------

.pagination {
	margin-bottom: 75px;
}

.pagination__results {
	margin-bottom: 5px;
	display: block;
	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}

.pagination__container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	@include media-breakpoint-down(sm) {
		text-align: center;
		display: block;
	}
}

.pagination__links {
	@include media-breakpoint-down(sm) {
		margin-bottom: 30px;
	}
}

.pagination__links-item {
	margin-right: 14px;
	display: inline-block;
	@include media-breakpoint-between(md, lg) {
		margin-right: 12px;
	}
}

.pagination__links-link {
	font-size: 20px;
	font-weight: 700;
	color: $gold;
	@include hover {
		color: $tundora;
	}
}

.pagination__links-link--active {
	color: $tundora;
}

.pagination__direction-link {
	font-size: 20px;
	color: $tundora;
	display: inline-block;
	@include hover {
		color: $gold;
	}
}

.pagination__direction-link--disabled {
	opacity: 0.4;
	cursor: default;
	@include hover {
		color: $tundora;
	}
}

.pagination__direction-separator {
	font-size: 20px;
	margin: 0 16px;
	display: inline-block;
}
