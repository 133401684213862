//---------- team-links
//------------------------------------------------------------------------------

.team-links {
	margin-bottom: 130px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 85px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 10px;
	}
}

.team-links__container {
	padding: 140px 0;
	margin: 0 -10px;
	.btn {
		width: calc(25% - 20px);
		margin: 0 10px;
		min-width: auto;
	}
	@include media-breakpoint-between(md, lg) {
		padding: 110px 78px;
		.btn {
			width: calc(50% - 20px);
			margin-bottom: 30px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 108px 48px 90px;
		.btn {
			width: 100%;
			margin: 0 0 30px;
		}
	}
}
