//----------  fonts
//------------------------------------------------------------------------------

@font-face {
	font-family: 'Rajdhani';
	src: url('../../assets/fonts/rajdhani-medium.woff2') format('woff2'),
		 url('../../assets/fonts/rajdhani-medium.woff') format('woff'),
		 url('../../assets/fonts/rajdhani-medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Rajdhani';
	src: url('../../assets/fonts/rajdhani-bold.woff2') format('woff2'),
		 url('../../assets/fonts/rajdhani-bold.woff') format('woff'),
		 url('../../assets/fonts/rajdhani-bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
