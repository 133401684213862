//----------  footer
//------------------------------------------------------------------------------
.footer {
	background-color: $tundora;
	color: $white;
}

.footer__container {
	padding: 35px 0 23px;
	@include media-breakpoint-down(sm) {
		padding: 20px 0 6px;
	}
}

.footer__top,
.footer__bottom {
	display: flex;
	justify-content: space-between;
	@include media-breakpoint-down(sm) {
		display: block;
	}
}

.footer__top {
	margin-bottom: 17px;
}

.footer__logo {
	@include media-breakpoint-down(sm) {
		display: inline-block;
		margin-bottom: 26px;
	}
}

.footer__social-links {
	padding: 5px 2px;
	display: inline-block;
	@include media-breakpoint-down(sm) {
		width: 100%;
		padding: 0;
	}
}

.footer__social-link .font-ico-twitter {
	margin-top: 2px;
}

.footer__social-label {
	font-size: 15px;
	line-height: 18px;
	font-weight: 700;
	color: $white;
	display: inline-block;
	padding-right: 6px;
	position: relative;
	top: -6px;
}

.footer__social-link {
	position: relative;
	color: $gold;
	width: 30px;
	height: 30px;
	display: inline-block;
	margin-left: 10px;
	transition: $duration $easing;
	.icon {
		@include align(absolute, xy);
		font-size: 20px;
	}
	@include hover {
		color: $white;
	}
	@include media-breakpoint-down(sm) {
		margin-left: 7px;
	}
}

.footer__list {
	display: block;
	@include media-breakpoint-down(sm) {
		width: 100%;
		float: left;
		margin-bottom: 20px;
	}
}

.footer__list-item {
	display: inline-block;
	margin-right: 22px;
	@include media-breakpoint-down(sm) {
		width: 50%;
		margin-right: 0;
	}
}

.footer__list-link {
	font-size: 15px;
	line-height: 26px;
	color: $white;
	font-weight: 700;
	text-decoration: underline;
	@include hover {
		text-decoration: none;
	}
}

.footer__copyright {
	font-size: 15px;
	line-height: 26px;
	color: $white;
	font-weight: 700;
	@include media-breakpoint-down(sm) {
		clear: both;
		display: block;
	}
}
