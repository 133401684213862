//---------- useful-links
//------------------------------------------------------------------------------

.useful-links {
	margin-bottom: 100px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 110px;
		.module-title {
			margin-bottom: 80px;
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 35px;
	}
}

.useful-links__container {
	margin: 0 -10px;
	.btn {
		width: calc(33.333% - 20px);
		margin: 0 10px;
		min-width: auto;
	}
	@include media-breakpoint-down(lg) {
		margin: 0;
		.btn {
			width: 100%;
			max-width: 356px;
			display: block;
			margin: 0 auto 20px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 20px 0;
		.btn {
			max-width: 262px;
		}
	}
}
