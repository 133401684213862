@font-face {
	font-family: 'svgicons';
	src: url('../assets/fonts/svgicons.woff2') format('woff2'),
		url('../assets/fonts/svgicons.woff') format('woff'),
		url('../assets/fonts/svgicons.ttf') format('truetype');
}

@mixin font-styles {
	font-family: 'svgicons';
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
	display: block;
	line-height: 1;
}

%font {
	@include font-styles;
}

@function font-char($filename) {
	$char: "";

	@if $filename == ico-arrow-down {
		$char: '\E001';
	}
	@if $filename == ico-arrow-left {
		$char: '\E002';
	}
	@if $filename == ico-arrow-right {
		$char: '\E003';
	}
	@if $filename == ico-arrow-up {
		$char: '\E004';
	}
	@if $filename == ico-layout-grid {
		$char: '\E005';
	}
	@if $filename == ico-layout-list {
		$char: '\E006';
	}
	@if $filename == ico-linkedin {
		$char: '\E007';
	}
	@if $filename == ico-search {
		$char: '\E008';
	}
	@if $filename == ico-twitter {
		$char: '\E009';
	}

	@return $char;
}

@mixin font($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %font;
		} @else {
			@include font-styles;
		}
		content: font-char($filename);
	}
}

[class*='font-ico'] {
	display: inline-block;
}

.font-ico-arrow-down {
	@include font(ico-arrow-down);
}
.font-ico-arrow-left {
	@include font(ico-arrow-left);
}
.font-ico-arrow-right {
	@include font(ico-arrow-right);
}
.font-ico-arrow-up {
	@include font(ico-arrow-up);
}
.font-ico-layout-grid {
	@include font(ico-layout-grid);
}
.font-ico-layout-list {
	@include font(ico-layout-list);
}
.font-ico-linkedin {
	@include font(ico-linkedin);
}
.font-ico-search {
	@include font(ico-search);
}
.font-ico-twitter {
	@include font(ico-twitter);
}
