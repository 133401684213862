//----------  header
//------------------------------------------------------------------------------

.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background-color: $white;
	border-bottom: 2px solid $grey-light;
	padding: 32px 0;
	transition: top 0.2s $easing;
	transform: translateZ(0);
	will-change: transform;
	z-index: 100;
	@include media-breakpoint-down(lg) {
		height: 80px;
		padding: 22px 0;
	}
}

.header__container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header__logo {
	width: 194px;
}

.header__logo-img {
	max-height: 34px;
}

// Main navigation
.main-nav {
	padding-top: 3px;
	margin-right: 102px;
	@include media-breakpoint-down(lg) {
		padding-top: 0;
		margin-right: 0;
		background-color: $white;
	}
}

.main-nav__container {
	position: relative;
	@include media-breakpoint-down(lg) {
		position: fixed;
		top: 80px;
		right: -100%;
		width: 100%;
		height: calc(100vh - 80px);
		max-width: 375px;
		padding-left: 5px;
		background-color: $gold;
		transition: 0.5s $easing-bezier;
	}
	@include media-breakpoint-down(sm) {
		max-width: 100%;
		overflow-y: auto;
		// sass-lint:disable no-vendor-prefixes
		-webkit-overflow-scrolling: touch;
	}
}

.main-nav__container--opened {
	right: 0;
}

.main-nav__list {
	font-size: 0;
	line-height: 1;
}

.main-nav__list-item {
	margin-left: 20px;
	display: inline-block;
	@include media-breakpoint-down(lg) {
		margin-left: 0;
		border-bottom: 1px solid $gold;
		display: block;
	}
}

.main-nav__list-link {
	position: relative;
	font-size: 16px;
	font-weight: 500;
	color: $gold;
	line-height: 24px;
	text-transform: uppercase;
	display: block;
	&::after {
		content: '';
		position: absolute;
		bottom: -2px;
		left: 50%;
		width: 50%;
		height: 3px;
		background-color: $gold;
		opacity: 0;
		visibility: hidden;
		transform: translate(-50%, 12px);
		pointer-events: none;
		transition: 0.2s $easing;
	}
	@include hover {
		&::after {
			width: 100%;
			opacity: 1;
			visibility: visible;
			transform: translate(-50%, 0);
		}
	}
	@include media-breakpoint-down(lg) {
		padding: 18px 16px;
	}
}

.main-nav__list-link--active {
	&::after {
		width: 100%;
		opacity: 1;
		visibility: visible;
		transform: translate(-50%, 0);
	}
	@include media-breakpoint-down(lg) {
		background-color: $gold;
		color: $white;
		&::after {
			display: none;
		}
	}
}

.nav__toggle {
	position: absolute;
	top: 22px;
	right: 35px;
	width: 38px;
	height: 36px;
	transition: $duration $easing-bezier;
	display: none;
	z-index: 150;
	@include media-breakpoint-down(lg) {
		display: block;
	}
	@include media-breakpoint-down(sm) {
		right: 20px;
	}
}

.nav__toggle--opened {
	.nav__toggle-line--top {
		margin-top: 0;
		transform: rotate(-45deg);
	}
	.nav__toggle-line--middle {
		width: 0;
	}
	.nav__toggle-line--bottom {
		margin-top: 0;
		transform: rotate(45deg);
	}
}

.nav__toggle-line {
	width: 100%;
	height: 6px;
	background-color: $gold;
	position: absolute;
	top: 50%;
	left: 0;
	transition: $duration $easing-bezier;
}

.nav__toggle-line--top {
	margin-top: -18px;
}

.nav__toggle-line--middle {
	margin-top: -3px;
}

.nav__toggle-line--bottom {
	margin-top: 11px;
}
