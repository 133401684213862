// --------------------------------------------------------------
// Controls
// --------------------------------------------------------------

// Hide native controls
.plyr--full-ui ::-webkit-media-controls {
    display: none;
}

// Playback controls
.plyr__controls {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    text-align: center;

    // Spacing
    > .plyr__control,
    .plyr__progress,
    .plyr__time,
    .plyr__menu,
    .plyr__volume {
        margin-left: ($plyr-control-spacing / 2);
    }

    .plyr__menu + .plyr__control,
    > .plyr__control + .plyr__menu,
    > .plyr__control + .plyr__control,
    .plyr__progress + .plyr__control {
        margin-left: floor($plyr-control-spacing / 4);
    }

    > .plyr__control:first-child,
    > .plyr__control:first-child + [data-plyr='pause'] {
        margin-left: 0;
        margin-right: auto;
    }

    // Hide empty controls
    &:empty {
        display: none;
    }

    @media (min-width: $plyr-bp-sm) {
        > .plyr__control,
        .plyr__menu,
        .plyr__progress,
        .plyr__time,
        .plyr__volume {
            margin-left: $plyr-control-spacing;
        }
    }
}

// Audio controls
.plyr--audio .plyr__controls {
    background: $plyr-audio-controls-bg;
    border-radius: inherit;
    color: $plyr-audio-control-color;
    padding: $plyr-control-spacing;
}

// Video controls
.plyr--video .plyr__controls {
    background-color: #4b4b4b;
	border-radius: 24px;
    bottom: 0;
    color: $plyr-video-control-color;
    left: 0;
    padding: 8px 10px;
    position: absolute;
    right: 0;
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
    z-index: 3;
}

// Hide video controls
.plyr--video.plyr--hide-controls .plyr__controls {
    opacity: 0;
    pointer-events: none;
    transform: translateY(100%);
}

// Some options are hidden by default
.plyr [data-plyr='captions'],
.plyr [data-plyr='pip'],
.plyr [data-plyr='airplay'],
.plyr [data-plyr='fullscreen'] {
    display: none;
}
.plyr--captions-enabled [data-plyr='captions'],
.plyr--pip-supported [data-plyr='pip'],
.plyr--airplay-supported [data-plyr='airplay'],
.plyr--fullscreen-enabled [data-plyr='fullscreen'] {
    display: inline-block;
}
