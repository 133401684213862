//---------- page-title
//------------------------------------------------------------------------------

.page-title {
	padding-top: 100px;
	margin-bottom: 25px;
	& ~ .news-details {
		margin-top: 0;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 40px;
		margin-bottom: 10px;
	}
}
