//---------- follow-us
//------------------------------------------------------------------------------

.follow-us {
	position: relative;
	width: 100%;
	padding-bottom: 67.65%;
	background-color: $white;
	border: 1px solid $gold;
	@include media-breakpoint-down(sm) {
		margin-bottom: 62px;
	}
}

.follow-us__text {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 40px;
	@include media-breakpoint-down(lg) {
		padding: 20px 18px;
	}
}

.follow-us__title {
	margin-bottom: 5px;
	@include media-breakpoint-down(lg) {
		margin-bottom: 2px;
	}
}

.follow-us__description {
	max-width: 400px;
}

.follow-us__social {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 30px 40px;
	@include media-breakpoint-down(lg) {
		padding: 20px 18px;
		line-height: 0;
	}
}

.follow-us__social-link {
	color: $white;
	width: 60px;
	height: 60px;
	background-color: $gold;
	position: relative;
	margin-right: 20px;
	border: 1px solid transparent;
	transition: $duration $easing;
	display: inline-block;
	.icon {
		@include align(absolute, xy);
		font-size: 40px;
	}
	@include hover {
		border-color: $gold;
		color: $gold;
		background-color: $white;
	}
	@include media-breakpoint-down(lg) {
		width: 44px;
		height: 44px;
		.icon {
			font-size: 30px;
		}
	}
}
