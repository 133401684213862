//----------  variables
//------------------------------------------------------------------------------

$font-default: 'Rajdhani', Calibri, Segoe, 'Segoe UI', sans-serif;

// Colors
$white: #fff;
$black: #000;
$grey-light: #ededed;
$tundora: #464646;
$gold: #bc924f;
$merino: #f8f3ee;
$parchment: #f4ecdc;

$site-colors: (
	'black': $black,
	'white': $white,
	'tundora': $tundora,
	'gold': $gold
) !default;

$duration: 0.3s;
$duration-long: 0.85s;
$easing: ease-in-out;
$easing-bezier: cubic-bezier(0.475, 0.425, 0, 0.995);

//----------  Bootstrap grid
//------------------------------------------------------------------------------

$enable-grid-classes: true !default;

//----------  Media queries breakpoints - Grid breakpoint
//------------------------------------------------------------------------------

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
) !default;

//----------  Grid containers
//------------------------------------------------------------------------------

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px
) !default;

//----------  Grid columns
//------------------------------------------------------------------------------

$grid-columns: 12 !default;
$grid-gutter-width: 24px !default;
