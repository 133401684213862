//----------  banner-slider
//------------------------------------------------------------------------------

.banner-slider {
	height: 600px;
	position: relative;
	margin-bottom: 110px;
	@include media-breakpoint-down(lg) {
		.slick-arrow {
			// sass-lint:disable no-important
			display: none !important;
		}
	}
	@include media-breakpoint-between(md, lg) {
		height: 41.675vw;
	}
	@include media-breakpoint-down(sm) {
		height: 83.5vw;
		margin-bottom: 58px;
	}
}

.banner-slider__container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.slick-slide {
		overflow: hidden;
	}
}

.banner-slider__slide {
	width: 100%;
	height: 100%;
	transform: scale(1.05);
	transition: $duration-long $easing-bezier;
	position: relative;
	.slick-active & {
		transform: scale(1);
	}
}

.banner-slider__content {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	text-align: center;
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	padding: 0 35px;
	transform: translateY(-50%);
	z-index: 10;
	@include media-breakpoint-between(md, lg) {
		max-width: 460px;
	}
	@include media-breakpoint-down(sm) {
		padding: 0 20px;
	}
}

.banner-slider__title {
	color: $white;
	text-shadow: 4px 4px 12px rgba($black, 0.5);
}
