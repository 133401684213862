//---------- team-list
//------------------------------------------------------------------------------

.team-list {
	margin-bottom: 125px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 40px;
		.module-title {
			margin-bottom: 40px;
			.module-title__text {
				margin-bottom: 20px;
			}
			&::before {
				top: 20px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 90px;
	}
}

.team-list__container {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -11px;
	@include media-breakpoint-down(sm) {
		margin: 0;
		display: block;
	}
}

.team-list__item {
	flex-basis: 33.333%;
	max-width: 33.333%;
	padding: 0 11px;
	margin-bottom: 42px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 32px;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
		max-width: 100%;
		padding: 0;
		margin-bottom: 38px;
	}
}

.team-list__image {
	transition: transform $duration-long $easing-bezier;
	margin-bottom: 15px;
	cursor: pointer;
	@include hover {
		transform: scale(0.95);
	}
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 10px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 8px;
	}
}

.team-list__name {
	cursor: pointer;
}

.team-list__position {
	font-size: 30px;
	line-height: 40px;
	display: block;
	cursor: pointer;
	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 30px;
	}
}

.team-list__biography {
	display: none;
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($black, 0.8);
	visibility: hidden;
	opacity: 0;
	transition: 0.6s $easing-bezier;
	display: none;
	z-index: 200;
	.loaded & {
		display: block;
	}
}

.modal-overlay--visible {
	opacity: 1;
	visibility: visible;
}

.modal {
	@include align(absolute, xy);
	background-color: $white;
	padding: 40px;
	width: 100%;
	max-width: 1000px;
	@include media-breakpoint-between(md, lg) {
		max-width: 700px;
	}
	@include media-breakpoint-down(sm) {
		width: calc(100% - 50px);
		left: 20px;
		transform: translate(0, -50%);
		padding: 38px 20px 30px 30px;
	}
}

.modal__close-btn {
	position: absolute;
	top: -25px;
	right: -25px;
	width: 50px;
	height: 50px;
	border: 1px solid transparent;
	background-color: $gold;
	transition: $duration $easing-bezier;
	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 32px;
		height: 4px;
		background-color: $white;
		transition: $duration $easing-bezier;
		display: block;
	}
	&::before {
		transform: translate(-50%, -50%) rotate(45deg);
	}
	&::after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
	@include hover {
		background-color: $white;
		border-color: $gold;
		&::before,
		&::after {
			background-color: $gold;
		}
	}
}

.modal__overflow {
	margin: 0 -11px;
	@include media-breakpoint-down(sm) {
		margin: 0;
		max-height: calc(90vh - 100px);
		overflow-y: auto;
	}
}

.modal__image {
	width: 43%;
	padding: 0 11px;
	vertical-align: top;
	display: inline-block;
	@include media-breakpoint-between(md, lg) {
		width: 37%;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
		padding: 0;
		margin-bottom: 10px;
	}
}

.modal__overflow-text {
	width: 57%;
	padding: 0 11px;
	max-height: calc(90vh - 100px);
	overflow-y: auto;
	vertical-align: top;
	display: inline-block;
	.team-list__name {
		cursor: default;
	}
	.team-list__position {
		margin-bottom: 45px;
		cursor: default;
	}
	p {
		max-width: 450px;
	}
	@include media-breakpoint-between(md, lg) {
		width: 63%;
		padding: 0 9px 0 34px;
		.team-list__position {
			margin-bottom: 15px;
		}
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
		padding: 0;
		.team-list__position {
			margin-bottom: 15px;
		}
	}
}
