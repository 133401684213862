//---------- module-slider
//------------------------------------------------------------------------------

.module-slider {
	margin-bottom: 112px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 55px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 62px;
	}
}

.slider {
	margin: 0 -11px;
	.slick-list {
		overflow: visible;
	}
	.slick-dots {
		top: 600px;
		bottom: auto;
		button {
			background-color: #e3d2bc;
			@include hover {
				background-color: $gold;
			}
		}
		.slick-active {
			button {
				background-color: $gold;
				@include hover {
					background-color: $gold;
				}
			}
		}
	}
	@media only screen and (min-width: 992px) and (max-width: 1270px) {
		.slick-dots {
			top: 47vw;
		}
	}
	@include media-breakpoint-only(md) {
		.slick-dots {
			top: 45.5vw;
		}
	}
	@include media-breakpoint-between(md, lg) {
		.slick-dots {
			li {
				margin: 0 8px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.slick-dots {
			top: 56vw;
			li {
				margin: 0 6px;
			}
			button {
				width: 36px;
			}
		}
	}
	@media only screen and (max-width: 500px) {
		.slick-dots {
			top: 54vw;
		}
	}
	@media only screen and (max-width: 360px) {
		.slick-dots {
			top: 52vw;
		}
	}
}

.slider__slide {
	padding: 0 11px;
}

.slider__image {
	padding-bottom: 60%;
	margin-bottom: 50px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 60px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 35px;
	}
}

.slider__caption-text {
	font-size: 20px;
	line-height: 30px;
	font-weight: 500;
	max-width: 800px;
	opacity: 0;
	transition: opacity $duration $easing;
	.slick-active & {
		opacity: 1;
	}
}
