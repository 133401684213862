//---------- module-error
//------------------------------------------------------------------------------

.module-error {
	padding: 98px 0 0;
	margin-bottom: 150px;
	@include media-breakpoint-only(md) {
		padding: 40px 0 0;
		margin-bottom: 92px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 40px;
		margin-bottom: 80px;
	}
}


.module-error__title {
	margin-bottom: 26px;
	@include media-breakpoint-down(sm) {
		margin-bottom: 20px;
	}
}

.module-error__text {
	font-size: 30px;
	line-height: 40px;
	font-weight: 700;
	@include media-breakpoint-down(md) {
		font-size: 25px;
		line-height: 30px;
	}
}
