//----------  layout
//------------------------------------------------------------------------------

body {
	font-family: $font-default;
	font-size: 20px;
	font-weight: 500;
	color: $tundora;
	line-height: 30px;
}

.main {
	position: relative;
	width: 100%;
	overflow: hidden;
	padding-top: 100px;
}

.wrap {
	margin: 0 auto;
	max-width: 1270px;
	padding: 0 35px;
	width: 100%;
	@include media-breakpoint-down(sm) {
		padding-left: 20px;
		padding-right: 20px;
	}
}
