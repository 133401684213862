//---------- search-form
//------------------------------------------------------------------------------

.search {
	position: absolute;
	top: 2px;
	right: 0;
	@include media-breakpoint-down(lg) {
		position: relative;
		top: auto;
		right: auto;
		padding-bottom: 40px;
	}
}

.search__button {
	position: relative;
	font-size: 25px;
	color: $gold;
	display: inline-block;
	&::after {
		content: '';
		position: absolute;
		bottom: -2px;
		left: 50%;
		width: 50%;
		height: 3px;
		background-color: $gold;
		opacity: 0;
		visibility: hidden;
		transform: translate(-50%, 12px);
		pointer-events: none;
		transition: 0.2s $easing;
	}
	@include hover {
		&::after {
			width: 100%;
			opacity: 1;
			visibility: visible;
			transform: translate(-50%, 0);
		}
	}
	@include media-breakpoint-down(lg) {
		display: none;
	}
}

.search__button--active {
	&::after {
		width: 100%;
		opacity: 1;
		visibility: visible;
		transform: translate(-50%, 0);
	}
}

.search-form {
	width: 100%;
	background-color: $gold;
	padding: 22px 0;
	.header & {
		position: fixed;
		top: 100px;
		left: 0;
		transition: top $duration $easing;
		display: none;
	}
	@include media-breakpoint-down(lg) {
		.header & {
			padding: 18px 0;
			position: relative;
			top: auto;
			left: auto;
			display: block;
			.wrap {
				padding: 0 20px 0 15px;
			}
		}
	}
}

.search-form__container {
	width: 100%;
	position: relative;
}

.search-form__input {
	font-size: 40px;
	font-weight: 700;
	color: $white;
	line-height: 46px;
	background: none;
	height: auto;
	padding: 0 18px 8px;
	border-bottom: 1px solid $white;
	&::placeholder {
		color: $white;
	}
	@include media-breakpoint-between(md, lg) {
		padding-right: 120px;
		padding-left: 0;
		.header & {
			font-size: 25px;
			line-height: 40px;
			padding: 8px 120px 12px 12px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 2px 120px 10px 0;
		font-size: 25px;
		.header & {
			line-height: 40px;
			padding: 8px 120px 12px 12px;
		}
	}
}

.search-form__submit {
	position: absolute;
	top: 0;
	right: 0;
	border-color: $white;
	min-width: auto;
	@include hover {
		border-color: $white;
	}
	@include media-breakpoint-between(md, lg) {
		font-size: 20px;
		letter-spacing: 5px;
		.header & {
			font-size: 15px;
			line-height: 52px;
			letter-spacing: 2px;
			height: 50px;
			padding: 0 28px;
		}
	}
	@include media-breakpoint-down(sm) {
		font-size: 15px;
		line-height: 52px;
		letter-spacing: 2px;
		height: 50px;
		padding: 0 28px;
	}
}

// sass-lint:disable no-vendor-prefixes
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.header .search-form {
		top: 152px;
	}
}
// sass-lint:enable no-vendor-prefixes
