//---------- module-title
//------------------------------------------------------------------------------

.module-title {
	position: relative;
	width: 100%;
	margin-bottom: 84px;
	text-align: center;
	&::before {
		content: '';
		position: absolute;
		top: 24px;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: $tundora;
	}
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 60px;
		&::before {
			top: 50%;
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 30px;
		&::before {
			top: 20px;
		}
	}
}

.module-title__text {
	background-color: $white;
	padding: 0 80px;
	margin-bottom: 0;
	position: relative;
	display: inline-block;
	z-index: 10;
	@include media-breakpoint-between(md, lg) {
		padding: 0 60px;
		max-width: 460px;
	}
	@include media-breakpoint-down(sm) {
		max-width: 320px;
		padding: 0 20px;
	}
}

.module-title__description {
	font-size: 30px;
	font-weight: 500;
	line-height: 40px;
	max-width: 790px;
	margin: 0 auto;
	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 35px;
		max-width: 580px;
	}
	@include media-breakpoint-down(sm) {
		padding: 10px 20px;
	}
}
