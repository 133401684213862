//---------- cookies
//------------------------------------------------------------------------------

.cookies {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 10px 0;
	background-color: $white;
	border-bottom: 2px solid $grey-light;
	z-index: 50;
	& ~ .header {
		top: 52px;
	}
	& ~ .main {
		padding-top: 152px;
	}
}
.cookies__container {
	display: flex;
	justify-content: space-between;
	@include media-breakpoint-down(sm) {
		display: block;
	}
}
.cookies__buttons {
	padding-left: 30px;
	height: 28px;
	white-space: nowrap;
	.btn {
		min-width: auto;
		&:first-of-type {
			margin-right: 20px;
		}
	}
	@include media-breakpoint-between(md, lg) {
		.btn {
			padding-left: 12px;
			padding-right: 12px;
		}
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		padding-left: 0;
		.btn {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	@media only screen and (max-width: 360px) {
		white-space: normal;
		.btn {
			&:first-of-type {
				margin-bottom: 10px;
			}
		}
	}
}
.cookies__text {
	font-size: 16px;
	line-height: 24px;
	padding: 3px 0;
	margin-bottom: 0;
	@include media-breakpoint-between(md, lg) {
		line-height: 18px;
		padding: 0;
		margin-top: -2px;
	}
	@include media-breakpoint-down(sm) {
		line-height: 18px;
		padding: 0;
		margin-bottom: 10px;
	}
}
