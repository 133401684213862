//----------  selectric
//------------------------------------------------------------------------------

.selectric-wrapper {
	width: 100%;
	position: relative;
	cursor: pointer;
}
.selectric {
	width: 100%;
	position: relative;
	border: 1px solid $gold;
	background-color: $white;
	transition: $duration $easing;
	@include hover {
		background-color: $gold;
		.label {
			color: $white;
		}
		.selectric-arrow {
			color: $white;
		}
	}
	.label {
		font-family: $font-default;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 10px;
		padding-right: 40px;
		font-size: 20px;
		font-weight: 700;
		color: $gold;
		line-height: 38px;
		height: 38px;
		text-align: left;
		display: block;
	}
}
.selectric-arrow {
	position: absolute;
	right: 10px;
	top: 8px;
	font-size: 24px;
	color: $gold;
	transition: $duration $easing;
}
.selectric-open {
	z-index: 40;
	.selectric {
		border-color: $gold;
		.selectric-arrow {
			transform: rotate(180deg);
		}
	}
	.selectric-items {
		display: block;
	}
}
.selectric-disabled {
	opacity: 0.5;
	cursor: default;
	user-select: none;
}
.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	select {
		position: absolute;
		left: -100%;
		display: none;
	}
}
.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	background: none !important;
}
.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}
.selectric-items {
	display: none;
	width: auto;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	background-color: $gold;
	border-top: 2px solid $white;
	z-index: -1;
	.selectric-scroll {
		height: 100%;
		max-height: 300px;
		overflow-y: auto;
	}
	ul {
		margin-bottom: 0;
	}
	li {
		font-size: 20px;
		font-weight: 700;
		color: $white;
		line-height: 26px;
		padding: 8px 10px;
		transition: $duration $easing;
		border-bottom: 1px solid $white;
		display: block;
		cursor: pointer;
		&:first-of-type {
			display: none;
		}
		&:last-of-type {
			border-bottom: 0;
		}
		@include hover {
			background-color: $parchment;
			color: $gold;
		}
		&.selected,
		&.highlighted {
			background-color: $parchment;
			color: $gold;
		}
	}
}
