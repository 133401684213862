//---------- module-map
//------------------------------------------------------------------------------

.module-map {
	margin-bottom: 176px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 68px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 25px;
		margin-bottom: 60px;
	}
}

.address__info {
	margin-bottom: 22px;
}

.address__info .text--medium {
	display: block;
	text-align: left;
}

.contact__info {
	margin-bottom: 52px;
	@include media-breakpoint-down(lg) {
		margin-bottom: 22px;
	}
}

.contact__info-link {
	display: block;
	font-size: 30px;
	line-height: 40px;
	font-weight: 500;
	color: $tundora;
	@include hover {
		text-decoration: underline;
	}
}

.address__block-title {
	margin-bottom: 5px;
	@include media-breakpoint-down(lg) {
		font-size: 30px;
		line-height: 40px;
	}
}

.address__block-paragraph {
	margin-bottom: 0;
	@include media-breakpoint-down(lg) {
		font-size: 30px;
		line-height: 40px;
	}
}

.follow__block {
	@include media-breakpoint-only(md) {
		margin-bottom: 30px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 16px;
	}
}

.follow__block-title {
	margin-bottom: 6px;
	@include media-breakpoint-down(lg) {
		font-size: 30px;
		line-height: 40px;
	}
}

.follow__block-icons {
	color: $gold;
	width: 50px;
	height: 50px;
	background-color: $white;
	display: inline-block;
	position: relative;
	margin-right: 6px;
	border: 1px solid $gold;
	transition: $duration $easing;
	.icon {
		@include align(absolute, xy);
		font-size: 30px;
	}
	@include hover {
		border-color: transparent;
		color: $white;
		background-color: $gold;
	}
}
