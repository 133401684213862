//---------- news-list
//------------------------------------------------------------------------------

.news-list {
	margin-bottom: 8px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 38px;
	}
}

.news-list__item-type {
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 4px;
	text-transform: uppercase;
	display: block;
}

.news-list__item-date {
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 4px;
	text-transform: uppercase;
	margin-bottom: 12px;
	display: block;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 16px;
	}
}

.news-list .pagination {
	margin-bottom: 125px;
	padding-top: 30px;
	@include media-breakpoint-between(md, lg) {
		padding-top: 0;
		margin-top: -12px;
		margin-bottom: 80px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 70px;
	}
}

// Grid view
.news-list--grid-view {
	.news-list__container {
		margin: 0 -12px;
	}
	.news-list__item {
		width: calc(33.333% - 24px);
		margin: 0 12px 48px;
		vertical-align: top;
		display: inline-block;
		@include hover {
			.news-list__item-image {
				transform: scale(0.9);
			}
		}
	}
	.news-list__item-image {
		position: relative;
		width: 100%;
		padding-bottom: 68%;
		margin-bottom: 9px;
		transition: transform $duration-long $easing-bezier;
	}
	.news-list__item-title {
		margin-bottom: 15px;
	}
	@include media-breakpoint-between(md, lg) {
		.news-list__item {
			margin-bottom: 54px;
		}
		.news-list__item-image {
			margin-bottom: 12px;
		}
		.news-list__item-title {
			margin-bottom: 20px;
		}
	}
	@include media-breakpoint-down(sm) {
		.news-list__container {
			margin: 0;
		}
		.news-list__item {
			width: 100%;
			margin: 0 0 40px;
		}
		.news-list__item-image {
			margin-bottom: 10px;
		}
		.news-list__item-title {
			margin-bottom: 30px;
		}
	}
}

// List view
.news-list--list-view {
	.news-list__item {
		width: 100%;
		position: relative;
		padding-right: 220px;
		padding-bottom: 10px;
		margin-bottom: 20px;
		border-bottom: 1px solid $gold;
	}
	.news-list__item-image {
		display: none;
	}
	.news-list__item-date {
		margin-bottom: 10px;
	}
	.news-list__item-link {
		position: absolute;
		bottom: 14px;
		right: 52px;
	}
	@include media-breakpoint-between(md, lg) {
		.news-list__item-link {
			right: 0;
			font-size: 20px;
		}
		.pagination {
			padding-top: 46px;
		}
	}
	@include media-breakpoint-down(sm) {
		.news-list__item {
			padding-right: 0;
			padding-bottom: 4px;
		}
		.news-list__item-link {
			position: relative;
			bottom: auto;
			right: auto;
			font-size: 20px;
		}
		.news-list__item-title {
			margin-bottom: 6px;
		}
	}
}

.news-list__noresults {
	padding: 80px 0 100px;
	@include media-breakpoint-down(sm) {
		padding: 40px 0 50px;
	}
}
