//----------  slick slider
//------------------------------------------------------------------------------
.slick-slider {
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	display: block;
	position: relative;
	touch-action: pan-y;
	user-select: none;
	.slick-track,
	.slick-list {
		height: 100%;
		transform: translate3d(0, 0, 0);
	}
}

.slick-list {
	display: block;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
	&:focus {
		outline: none;
	}
	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-track {
	@extend %clearfix;
	display: block;
	left: 0;
	position: relative;
	top: 0;
	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
	outline: 0;
	[dir='rtl'] & {
		float: right;
	}
	img {
		display: block;
	}
	> div {
		height: 100%;
	}
	&.slick-loading img {
		display: none;
	}
	&.dragging img {
		pointer-events: none;
	}
	.slick-initialized & {
		display: block;
	}
	.slick-loading & {
		visibility: hidden;
	}
	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow {
	position: absolute;
	top: 51.25%;
	transform: translateY(-50%);
	font-size: 70px;
	color: $gold;
	transition: color $duration $easing;
	z-index: 20;
	@include hover {
		color: $white;
	}
	&.slick-prev {
		left: 32px;
	}
	&.slick-next {
		right: 32px;
	}
	&.slick-hidden {
		display: none;
	}
}

.slick-dots {
	position: absolute;
	bottom: 14px;
	left: 0;
	width: 100%;
	text-align: center;
	font-size: 0;
	li {
		margin: 0 12px;
		display: inline-block;
	}
	button {
		background-color: $gold;
		width: 80px;
		height: 5px;
		text-indent: -9999px;
		transition: $duration $easing;
		display: block;
		@include hover {
			background-color: $white;
		}
	}
	.slick-active {
		button {
			background-color: $white;
			@include hover {
				background-color: $white;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		bottom: 4px;
	}
}
