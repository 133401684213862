//---------- job-offers
//------------------------------------------------------------------------------

.job-offers {
	margin-bottom: 120px;
	.module-title {
		margin-bottom: 45px;
	}
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 85px;
		.module-title {
			margin-bottom: 60px;
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 55px;
	}
}

.job-offer {
	width: 100%;
	padding: 30px;
	border: 1px solid $gold;
	@include media-breakpoint-down(lg) {
		padding: 30px 18px;
		margin-bottom: 22px;
	}
	@include media-breakpoint-down(sm) {
		padding-bottom: 26px;
	}
}

.job-offer__title {
	margin-bottom: 5px;
}

.job-offer__position {
	font-size: 30px;
	font-weight: 500;
	margin-bottom: 45px;
	display: block;
	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 30px;
		margin-bottom: 55px;
	}
}

.job-offer__link {
	min-width: 252px;
	@include media-breakpoint-down(lg) {
		min-width: 158px;
	}
}
