//---------- key-facts
//------------------------------------------------------------------------------

.key-facts {
	margin-bottom: 140px;
	.wrap {
		padding: 0 90px;
	}
	.slick-arrow {
		line-height: 0;
		@include hover {
			color: darken($gold, 10%);
		}
	}
	.slick-prev {
		left: -80px;
	}
	.slick-next {
		right: -80px;
	}
	@include media-breakpoint-down(lg) {
		.slick-arrow {
			font-size: 30px;
			width: 40px;
			height: 40px;
			&::before,
			&::after {
				content: '';
				position: absolute;
				width: 20px;
				height: 4px;
				background-color: $gold;
			}
			&::before {
				top: 12px;
			}
			&::after {
				bottom: 12px;
			}
			.icon {
				display: none;
			}
		}
		.slick-prev {
			&::before {
				right: 14px;
				transform: rotate(135deg);
			}
			&::after {
				right: 14px;
				transform: rotate(-135deg);
			}
		}
		.slick-next {
			&::before {
				left: 14px;
				transform: rotate(45deg);
			}
			&::after {
				left: 14px;
				transform: rotate(-45deg);
			}
		}
	}
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 86px;
		.module-title {
			margin-bottom: 30px;
		}
		.wrap {
			padding: 0 112px;
		}
		.slick-prev {
			left: -65px;
		}
		.slick-next {
			right: -65px;
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 68px;
		.wrap {
			padding: 0 58px;
		}
		.slick-prev {
			left: -45px;
		}
		.slick-next {
			right: -45px;
		}
	}
}

.key-facts__container {
	margin: 0 -11px;
	@include media-breakpoint-down(sm) {
		padding-top: 8px;
		margin: 0;
	}
}

.key-facts__item {
	width: 33.333%;
	padding: 0 11px;
	display: inline-block;
	@include media-breakpoint-down(sm) {
		width: 100%;
		padding: 0;
	}
}

.key-facts__box {
	width: 100%;
	min-height: 260px;
	padding: 62px 20px 20px;
	text-align: center;
	border: 1px solid $gold;
	@include media-breakpoint-down(lg) {
		padding-top: 22px;
		min-height: 175px;
	}
}

.key-facts__value {
	font-size: 80px;
	font-weight: 700;
	margin-bottom: 10px;
	display: block;
	@include media-breakpoint-down(lg) {
		font-size: 60px;
		line-height: 60px;
		margin-bottom: 4px;
	}
}

.key-facts__text {
	font-size: 30px;
	font-weight: 500;
	line-height: 40px;
	display: block;
	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 30px;
	}
}
