//----------  banner
//------------------------------------------------------------------------------

.banner {
	height: 600px;
	position: relative;
	margin-bottom: 110px;
	@include media-breakpoint-between(md, lg) {
		height: 41.675vw;
	}
	@include media-breakpoint-down(sm) {
		height: 83.5vw;
		margin-bottom: 58px;
	}
}

.banner__content {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	text-align: center;
	width: 100%;
	max-width: 982px;
	margin: 0 auto;
	padding: 0 35px;
	transform: translateY(-50%);
	z-index: 10;
	@include media-breakpoint-between(md, lg) {
		max-width: 580px;
	}
	@include media-breakpoint-down(sm) {
		padding: 0 20px;
	}
}

.banner__title {
	color: $white;
	text-shadow: 4px 4px 12px rgba($black, 0.5);
}
