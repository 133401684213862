//---------- module-intro
//------------------------------------------------------------------------------

.module-intro {
	text-align: center;
	margin-bottom: 80px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 65px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 45px;
	}
}

.module-intro__text {
	font-size: 40px;
	line-height: 55px;
	margin-bottom: 0;
	@include media-breakpoint-down(lg) {
		font-size: 30px;
		line-height: 40px;
	}
}
