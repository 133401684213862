//---------- contact-form
//------------------------------------------------------------------------------

.contact-form {
	padding: 100px 0;
	.module-title {
		margin-bottom: 64px;
	}
	@include media-breakpoint-down(lg) {
		.module-title {
			margin-bottom: 24px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 60px 0;
	}
}

.form__steps {
	width: 100%;
	margin-bottom: 25px;
}

.form__steps-step {
	position: relative;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	margin-left: 55px;
	background-color: $white;
	border: 1px solid $gold;
	font-size: 16px;
	font-weight: 500;
	color: $gold;
	line-height: 24px;
	text-align: center;
	display: inline-block;
	&::before {
		content: '';
		width: 56px;
		height: 1px;
		position: absolute;
		top: 50%;
		right: 100%;
		background-color: $gold;
		z-index: -1;
	}
	&:first-of-type {
		margin-left: 0;
		&::before {
			display: none;
		}
	}
}

.form__steps-step--active {
	background-color: $gold;
	font-weight: 700;
	color: $white;
}

.contact-form__label {
	font-weight: 700;
	color: $gold;
	margin-bottom: 5px;
	display: inline-block;
}

.contact-form__input {
	color: $black;
}

.contact-form__textarea-container {
	width: 100%;
	position: relative;
	border: 1px solid $gold;
}

.contact-form__textarea {
	padding: 24px 32px;
	resize: none;
	@include media-breakpoint-down(sm) {
		padding: 22px 18px;
	}
}

.contact-form__inline-text {
	margin-left: 10px;
	display: inline-block;
	@include media-breakpoint-down(sm) {
		margin: -7px 0 7px;
		display: block;
	}
}

.contact-form__inline-link {
	position: relative;
	color: $tundora;
	display: inline-block;
	&::after {
		content: '';
		position: absolute;
		bottom: 6px;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: $tundora;
	}
	@include hover {
		&::after {
			background-color: transparent;
		}
	}
}

.form__upload-file .contact-form__label {
	font-size: 20px;
	border-bottom: 1px solid $gold;
	margin-right: 18px;
	@include hover {
		border-color: transparent;
	}
	@include media-breakpoint-down(sm) {
		margin-right: 8px;
	}
}

.captcha {
	text-align: center;
	max-width: 600px;
	margin: 0 auto;
}

.g-recaptcha {
	display: inline-block;
}

.captcha__title {
	margin-bottom: 58px;
}

.contact-form__message {
	text-align: center;
	max-width: 860px;
	margin: 0 auto 105px;
}

.contact-form__message-title {
	margin-bottom: 82px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 108px;
	}
}

.contact-form__message-status {
	margin-bottom: 14px;
	@include media-breakpoint-down(lg) {
		margin-bottom: 0;
	}
}
