//----------  forms
//------------------------------------------------------------------------------
input,
textarea,
select {
	font-family: $font-default;
	background: $white;
	border-radius: 0;
	box-shadow: none;
	font-size: 20px;
	font-weight: 500;
	color: $tundora;
	height: 40px;
	width: 100%;
	padding: 0 12px;
	border: 1px solid $gold;
	appearance: none;
	&::placeholder {
		color: #aaa;
	}
}

textarea {
	border: 0;
	height: 148px;
	@include media-breakpoint-down(sm) {
		height: 132px;
	}
}

.input-validation-error {
	border-color: #da2d2a;
}

.form__error-message {
	font-size: 16px;
	color: #da2d2a;
}

.form__row {
	margin-bottom: 25px;
	@include media-breakpoint-down(sm) {
		margin-bottom: 0;
		[class^='col-'] {
			margin-bottom: 12px;
		}
	}
}

.form__upload-container {
	position: relative;
	padding-right: 220px;
	@include media-breakpoint-down(sm) {
		padding-top: 20px;
		padding-right: 0;
	}
}

.form__upload-file {
	position: relative;
	padding-top: 5px;
	@include media-breakpoint-down(lg) {
		padding-top: 0;
		margin-top: -5px;
	}
}

.form__upload-input {
	@include visually-hidden;
}

.form__upload-ext {
	font-size: 16px;
	display: inline-block;
}

.form__disclaimer {
	padding-top: 22px;
	@include media-breakpoint-between(md, lg) {
		padding-top: 18px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 10px;
	}
}

.form__disclaimer-text {
	font-size: 12px;
	line-height: 16px;
	max-width: 596px;
}

.form__upload-uploaded {
	font-size: 18px;
	color: $tundora;
	padding-top: 4px;
	display: block;
}

.form__upload-button {
	position: absolute;
	top: 0;
	right: 0;
	min-width: 182px;
	@include media-breakpoint-between(md, lg) {
		top: 6px;
		min-width: 158px;
	}
	@include media-breakpoint-down(sm) {
		top: -4px;
		min-width: 116px;
	}
}
