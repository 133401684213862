//---------- module-video
//------------------------------------------------------------------------------

.module__video {
	margin-bottom: 80px;
}

.video__container {
	width: 100%;
	position: relative;
}
