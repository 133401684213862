//---------- news-details
//------------------------------------------------------------------------------

.news-details {
	margin-top: -90px;
	@include media-breakpoint-between(md, lg) {
		margin-top: -88px;
	}
	@include media-breakpoint-down(sm) {
		margin-top: -50px;
	}
}

.news-details .wrap {
	position: relative;
}

.share-article {
	position: absolute;
	top: 90px;
	left: -23.5%;
	@include media-breakpoint-down(lg) {
		position: relative;
		top: auto;
		left: auto;
		margin-bottom: 32px;
	}
}

.share-article--hidden {
	display: none;
	@include media-breakpoint-down(lg) {
		display: block;
	}
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 64px;
	}
}

.share-article__title {
	font-size: 20px;
	line-height: 30px;
	font-weight: 500;
	display: block;
	margin-bottom: 4px;
}

.share-article__link {
	color: $white;
	width: 40px;
	height: 40px;
	background-color: $gold;
	display: inline-block;
	position: relative;
	margin-right: 6px;
	border: 1px solid transparent;
	transition: $duration $easing;
	.icon {
		@include align(absolute, xy);
		font-size: 26px;
	}
	@include hover {
		border-color: $gold;
		color: $gold;
		background-color: $white;
	}
}

.news-details__type,
.news-details__date {
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 4px;
	display: block;
	text-transform: uppercase;
}

.news-details__type {
	font-weight: 700;
}

.news-details__date {
	font-weight: 500;
	margin-bottom: 54px;
	@include media-breakpoint-down(lg) {
		margin-bottom: 22px;
	}
}
