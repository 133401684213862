//---------- case-module
//------------------------------------------------------------------------------

.case-module {
	margin-bottom: 30px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 24px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 10px;
	}
}

.case-cards__container {
	margin: -20px -10px 0 -20px;
	@include media-breakpoint-down(sm) {
		margin-top: -2px;
	}
}

.case-cards__item {
	width: 50%;
	position: relative;
	padding: 0 20px;
	margin-bottom: 90px;
	vertical-align: top;
	display: inline-block;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 46px;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
		margin-bottom: 50px;
	}
}

.case-cards__number {
	font-size: 170px;
	font-weight: 700;
	color: $gold;
	text-align: right;
	position: absolute;
	top: -12px;
	left: 20px;
	width: 180px;
	opacity: 0.4;
	display: block;
	@include media-breakpoint-down(lg) {
		font-size: 90px;
		line-height: 90px;
		top: 5px;
		left: 0;
		width: 88px;
	}
	@include media-breakpoint-down(sm) {
		width: 102px;
	}
}

.case-cards__title {
	font-size: 60px;
	font-weight: 700;
	line-height: 70px;
	padding-left: 202px;
	display: block;
	@include media-breakpoint-down(lg) {
		font-size: 40px;
		line-height: 50px;
		padding-left: 80px;
		margin-bottom: 10px;
	}
	@include media-breakpoint-down(sm) {
		padding-left: 96px;
	}
}

.case-cards__text {
	font-size: 30px;
	font-weight: 500;
	line-height: 40px;
	padding-left: 202px;
	display: block;
	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 30px;
		padding-left: 80px;
	}
	@include media-breakpoint-down(sm) {
		padding-left: 96px;
	}
}
