//---------- featured-news
//------------------------------------------------------------------------------

.featured-news {
	width: 100%;
	margin-bottom: 120px;
	@include hover {
		.featured-news__image {
			transform: scale(0.925);
		}
	}
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 55px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 48px;
	}
}

.featured-news__image {
	position: relative;
	width: 100%;
	padding-bottom: 68%;
	margin-bottom: 14px;
	transition: transform $duration-long $easing-bezier;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 12px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 10px;
	}
}

.featured-news__type {
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 4px;
	text-transform: uppercase;
	display: block;
}

.featured-news__date {
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 4px;
	text-transform: uppercase;
	margin-bottom: 12px;
	display: block;
}

.featured-news__title {
	max-width: 540px;
	margin-bottom: 22px;
	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 30px;
	}
}
