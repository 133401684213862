//---------- module-contact
//------------------------------------------------------------------------------

.cta-module {
	margin-bottom: 176px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 92px;
		.contact__content {
			padding: 80px 60px 68px;
		}
		.contact__content-btn {
			min-width: 280px;
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 62px;
		.contact__content {
			padding: 60px 0;
		}
		.contact__content-title {
			margin-bottom: 10px;
		}
		.contact__content-subtitle {
			margin-bottom: 20px;
		}
		.contact__content-btn {
			min-width: 140px;
			padding: 0 30px;
		}
	}
}

.contact__image {
	text-align: center;
}

.contact__content {
	max-width: 720px;
	padding: 80px 0 60px;
	margin: 0 auto;
	@include media-breakpoint-down(lg) {
		padding: 80px 0 68px;
	}
}

.contact__content-title,
.contact__content-subtitle {
	font-size: 40px;
	line-height: 50px;
	color: $white;
	@include media-breakpoint-down(lg) {
		font-size: 30px;
		line-height: 40px;
	}
}

.contact__content-title {
	font-weight: 700;
	margin-bottom: 10px;
	@include media-breakpoint-down(lg) {
		margin-bottom: 20px;
	}
}

.contact__content-subtitle {
	min-height: 100px;
	font-weight: 500;
	margin-bottom: 40px;
}

.contact__content-btn {
	padding: 0 45px;
	@include media-breakpoint-between(md, lg) {
		min-width: 380px;
		letter-spacing: 2px;
	}
}
