//---------- community-list
//------------------------------------------------------------------------------

.community-list {
	margin-bottom: 130px;
	.module-title {
		margin-bottom: 54px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 12px;
	}
}

.community-list__container {
	margin: 0 -10px;
	@include media-breakpoint-down(sm) {
		margin: 0;
	}
}

.community-list__item {
	width: 50%;
	padding: 0 10px;
	margin-bottom: 30px;
	text-align: center;
	vertical-align: top;
	display: inline-block;
	@include media-breakpoint-down(sm) {
		width: 100%;
		padding: 0;
		margin-bottom: 55px;
	}
}

.community-list__item-image {
	position: relative;
	width: 100%;
	padding-bottom: 68%;
	margin-bottom: 20px;
	overflow: hidden;
	@include hover {
		.community-list__item-hover {
			opacity: 1;
			transform: scale(1);
		}
	}
}

.community-list__item-hover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid $gold;
	opacity: 0;
	transform: scale(1.1);
	transition: $duration-long $easing-bezier;
	z-index: 10;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		border-top: 17px solid $white;
		border-right: 20px solid $white;
		border-left: 20px solid $white;
		border-bottom: 17px solid $white;
		transition: $duration-long $easing-bezier;
	}
}

.community-list__item-title {
	position: absolute;
	top: 50%;
	left: 0;
	color: $white;
	width: 100%;
	padding: 0 30px;
	transform: translateY(-50%);
	text-shadow: 4px 4px 8px rgba($black, 0.5);
	z-index: 20;
}

.community-list__item-description {
	@include media-breakpoint-down(sm) {
		line-height: 35px;
	}
}
