//---------- module-stats
//------------------------------------------------------------------------------

.module__stats {
	margin-bottom: 112px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 65px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 12px;
	}
}

.stats__block {
	text-align: center;
	@include media-breakpoint-down(sm) {
		margin-bottom: 40px;
	}
}

.stats__block-value,
.stats__block-description {
	display: block;
}

.stats__block-value {
	font-size: 80px;
	font-weight: 700;
	margin-bottom: 35px;
	@include media-breakpoint-down(lg) {
		font-size: 60px;
		line-height: 60px;
		margin-bottom: 2px;
	}
}

.stats__block-description {
	font-size: 30px;
	line-height: 40px;
	font-weight: 500;
	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 30px;
	}
}
