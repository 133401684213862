//---------- module-quote
//------------------------------------------------------------------------------

.module__quote {
	margin-bottom: 80px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 58px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 25px;
		& + .image-text {
			padding-top: 40px;
		}
	}
}

.quote__text {
	font-size: 40px;
	line-height: 55px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 25px;
	@include media-breakpoint-down(lg) {
		font-size: 30px;
		line-height: 40px;
		margin-bottom: 30px;
	}
	@include media-breakpoint-down(lg) {
		margin-bottom: 20px;
	}
}

.quote__author {
	font-size: 30px;
	line-height: 40px;
	font-weight: 700;
	font-style: normal;
	text-align: center;
	display: block;
	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 30px;
	}
}

.module__quote--image {
	padding-top: 80px;
	padding-bottom: 90px;
	margin-bottom: 135px;
	.quote__text {
		line-height: 50px;
		margin-bottom: 42px;
		color: $white;
	}
	.quote__author {
		color: $white;
	}
	@include media-breakpoint-between(md, lg) {
		padding-bottom: 100px;
		margin-bottom: 90px;
		.quote__text {
			line-height: 40px;
			margin-bottom: 30px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: 40px;
		padding-bottom: 60px;
		margin-bottom: 72px;
		.quote__text {
			line-height: 40px;
			margin-bottom: 30px;
		}
	}
}
