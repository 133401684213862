//---------- module-button
//------------------------------------------------------------------------------

.module-button {
	text-align: center;
	margin-bottom: 120px;
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 80px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 60px;
	}
}
