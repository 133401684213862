//---------- projects-list
//------------------------------------------------------------------------------

.projects-list__item-image {
	position: relative;
	overflow: hidden;
	@include hover {
		.projects-list__item-hover {
			opacity: 1;
			transform: scale(1);
		}
	}
}

.projects-list__item-hover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid $gold;
	opacity: 0;
	transform: scale(1.1);
	transition: $duration-long $easing-bezier;
	z-index: 10;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		transition: $duration-long $easing-bezier;
	}
}

.projects-list__item-description {
	@include media-breakpoint-between(md, lg) {
		line-height: 35px;
	}
}

// Grid view
.projects-list--grid-view {
	margin-bottom: 42px;
	.projects-list__container {
		margin: 0 -10px;
	}
	.projects-list__item {
		width: 50%;
		padding: 0 10px;
		margin-bottom: 30px;
		text-align: center;
		vertical-align: top;
		display: inline-block;
	}
	.projects-list__item-image {
		width: 100%;
		padding-bottom: 68%;
		margin-bottom: 20px;
	}
	.projects-list__item-hover {
		&::after {
			border-top: 17px solid $white;
			border-right: 20px solid $white;
			border-left: 20px solid $white;
			border-bottom: 17px solid $white;
		}
	}
	.projects-list__item-title {
		position: absolute;
		top: 50%;
		left: 0;
		color: $white;
		width: 100%;
		padding: 0 30px;
		transform: translateY(-50%);
		text-shadow: 4px 4px 8px rgba($black, 0.5);
		z-index: 20;
	}
	.projects-list__item-description {
		padding: 0 25px;
		margin-bottom: 6px;
	}
	@include media-breakpoint-between(md, lg) {
		.projects-list__item {
			margin-bottom: 46px;
		}
		.projects-list__item-image {
			margin-bottom: 12px;
		}
		.projects-list__item-description {
			padding: 0;
			margin-bottom: 26px;
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 0;
		.projects-list__item {
			width: 100%;
			margin-bottom: 54px;
		}
		.projects-list__item-image {
			margin-bottom: 12px;
		}
		.projects-list__item-description {
			padding: 0;
			line-height: 35px;
			margin-bottom: 30px;
		}
	}
}

// List view
.projects-list--list-view {
	margin-bottom: 90px;
	.projects-list__item {
		position: relative;
		width: 100%;
		border-bottom: 1px solid $gold;
		padding-left: 305px;
		padding-bottom: 18px;
		margin-bottom: 10px;
	}
	.projects-list__item-image {
		width: 282px;
		height: 192px;
		position: absolute;
		top: 10px;
		left: 0;
		.projects-list__item-title {
			display: none;
		}
	}
	.projects-list__item-hover {
		&::after {
			border-top: 7px solid $white;
			border-right: 10px solid $white;
			border-left: 10px solid $white;
			border-bottom: 7px solid $white;
		}
	}
	.projects-list__item-title {
		color: $gold;
		margin-bottom: 15px;
		display: block;
	}
	.projects-list__item-description {
		height: 105px;
		margin-bottom: 10px;
	}
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 35px;
		.projects-list__item {
			padding-left: 238px;
			padding-bottom: 20px;
			margin-bottom: 12px;
		}
		.projects-list__item-image {
			width: 218px;
			height: 148px;
		}
		.projects-list__item-title {
			font-size: 20px;
			line-height: 32px;
			margin-bottom: 2px;
		}
		.projects-list__item-description {
			height: 95px;
			font-size: 16px;
			line-height: 20px;
			font-weight: 500;
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 35px;
		.projects-list__item {
			padding-left: 118px;
			padding-bottom: 18px;
			margin-bottom: 16px;
		}
		.projects-list__item-image {
			width: 110px;
			height: 74px;
			top: 6px;
		}
		.projects-list__item-title {
			font-size: 20px;
			line-height: 26px;
			margin-bottom: 2px;
		}
		.projects-list__item-description {
			height: auto;
			font-size: 16px;
			line-height: 20px;
			font-weight: 500;
		}
	}
}

.projects-list__noresults {
	padding: 80px 0 100px;
	@include media-breakpoint-down(sm) {
		padding: 40px 0 50px;
	}
}
