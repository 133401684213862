//----------  button
//------------------------------------------------------------------------------
a,
button {
	appearance: none;
	outline: none;
}

button,
input[type='button'],
input[type='submit'] {
	font-family: $font-default;
	background: none;
	border: 0;
	cursor: pointer;
	outline: none;
	padding: 0;
}

.btn {
	position: relative;
	font-size: 20px;
	font-weight: 700;
	color: $white;
	height: 60px;
	line-height: 58px;
	text-transform: uppercase;
	letter-spacing: 2.5px;
	min-width: 300px;
	background-color: $gold;
	border: 1px solid $gold;
	padding: 0 20px;
	border-radius: 30px;
	text-align: center;
	transform: translateZ(0);
	overflow: hidden;
	display: inline-block;
	transition: color 0.4s, background-color 10ms 0s;
	&::before {
		content: '';
		width: 110%;
		height: 110%;
		border-radius: 40px;
		position: absolute;
		top: -5%;
		left: -5%;
		background-color: $white;
		transition: transform 0.45s cubic-bezier(0.835, -0.005, 0.06, 1);
		transform-origin: right;
		transform: scaleX(0);
		will-change: transform;
		z-index: -1;
	}
	@include hover {
		background-color: transparent;
		color: $gold;
		transition: color 0.4s, background-color 10ms 0.45s;
		&::before {
			transform-origin: left;
			transform: scaleX(1);
		}
	}
	@include media-breakpoint-down(md) {
		font-size: 15px;
		line-height: 50px;
		min-width: 260px;
		height: 50px;
	}
}

.btn--medium {
	height: 40px;
	line-height: 38px;
	letter-spacing: 5px;
	padding: 0 42px;
	@include media-breakpoint-between(md, lg) {
		line-height: 40px;
	}
}

.btn--small {
	font-size: 14px;
	font-weight: 500;
	height: 30px;
	line-height: 28px;
	padding: 0 18px;
	letter-spacing: 0;
	text-transform: none;
}

.btn--outline {
	background-color: $white;
	color: $gold;
	letter-spacing: 5px;
	&::before {
		background-color: $gold;
	}
	@include hover {
		color: $white;
	}
	&.btn--disabled {
		@include hover {
			background-color: $white;
			color: $gold;
		}
	}
	@include media-breakpoint-down(md) {
		letter-spacing: 2px;
	}
}

.btn--alternate {
	background-color: $merino;
	border-color: transparent;
	color: $gold;
	@include hover {
		background-color: darken($merino, 5%);
		border-color: transparent;
	}
}

.btn--disabled {
	opacity: 0.5;
	cursor: default;
	&::before {
		display: none;
	}
}
