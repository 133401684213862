//---------- module-image
//------------------------------------------------------------------------------

.module__image {
	margin-bottom: 80px;
}

.image__block-img {
	width: 100%;
	height: auto;
	margin-bottom: 16px;
	@include media-breakpoint-down(lg) {
		margin-bottom: 8px;
	}
}

.image__block-caption {
	font-size: 20px;
	line-height: 30px;
	font-weight: 500;
	max-width: 800px;
	margin-bottom: 10px;
}
