//---------- search-results
//------------------------------------------------------------------------------

.search-results {
	width: 100%;
	padding-top: 70px;
	@include media-breakpoint-down(sm) {
		padding-top: 30px;
	}
}

.search-results__title {
	margin-bottom: 40px;
	@include media-breakpoint-down(sm) {
		margin-bottom: 10px;
	}
}

.search-results__list {
	margin-bottom: 40px;
}

.search-results__item {
	position: relative;
	width: 100%;
	padding-right: 220px;
	padding-bottom: 25px;
	margin-bottom: 24px;
	border-bottom: 1px solid $tundora;
	@include media-breakpoint-down(sm) {
		padding-right: 0;
		padding-bottom: 4px;
		margin-bottom: 15px;
	}
}

.search-results__item-title {
	font-size: 20px;
	font-weight: 700;
	color: $tundora;
	line-height: 40px;
	@include hover {
		text-decoration: underline;
	}
}

.search-results__item-description {
	margin-bottom: 0;
	@include media-breakpoint-down(sm) {
		max-height: 60px;
		position: relative;
		overflow: hidden;
		&::after {
			content: '...';
			position: absolute;
			bottom: 0;
			right: 0;
			background-color: $white;
			display: block;
		}
	}
}

.search-results__item-link {
	position: absolute;
	bottom: 26px;
	right: 26px;
	@include media-breakpoint-down(sm) {
		position: relative;
		bottom: auto;
		right: auto;
		font-size: 20px;
	}
}
