//---------- image-text
//------------------------------------------------------------------------------

.image-text {
	margin-bottom: 152px;
	@include media-breakpoint-down(lg) {
		margin-bottom: 50px;
	}
}

.image-text__block {
	.rte {
		margin-bottom: 52px;
	}
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 26px;
	}
}

.image-text__intro {
	font-size: 30px;
	line-height: 40px;
	font-weight: 700;
	margin-bottom: 16px;
	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 30px;
		margin-bottom: 10px;
	}
}

.image-text__stats {
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 20px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 40px;
	}
}

.image-text__stats-value {
	font-size: 80px;
	font-weight: 700;
	line-height: 80px;
	display: block;
	@include media-breakpoint-down(lg) {
		font-size: 60px;
		line-height: 60px;
	}
}

.image-text__stats-description {
	font-size: 30px;
	line-height: 40px;
	font-weight: 500;
	margin-top: -4px;
	display: block;
	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 30px;
	}
}
