//----------  typography
//------------------------------------------------------------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $font-default;
	font-weight: 700;
	color: $tundora;
}

h1, .h1 {
	font-size: 80px;
	line-height: 80px;
	@include media-breakpoint-down(lg) {
		font-size: 40px;
		line-height: 45px;
	}
}

h2, .h2 {
	font-size: 60px;
	line-height: 70px;
	@include media-breakpoint-down(lg) {
		font-size: 40px;
		line-height: 50px;
	}
}

h3, .h3 {
	font-size: 40px;
	line-height: 50px;
	@include media-breakpoint-down(lg) {
		font-size: 30px;
		line-height: 40px;
	}
}

h4, .h4 {
	font-size: 30px;
	line-height: 40px;
	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 30px;
	}
}

h5, .h5 {
	font-size: 20px;
	line-height: 28px;
}

h6, .h6 {
	font-size: 16px;
	line-height: 20px;
}

p {
	font-size: 20px;
	font-weight: 500;
	color: $tundora;
	line-height: 30px;
	margin-bottom: 10px;
	a {
		color: $gold;
		@include hover {
			text-decoration: underline;
		}
	}
}

.link {
	font-size: 20px;
	font-weight: 700;
	color: $gold;
	line-height: 26px;
	display: inline-block;
	@include hover {
		text-decoration: underline;
	}
	@include media-breakpoint-down(lg) {
		font-size: 16px;
		line-height: 20px;
	}
}

img {
	max-width: 100%;
	height: auto;
}

.intro-text {
	font-size: 40px;
	line-height: 55px;
	margin-bottom: 50px;
	@include media-breakpoint-down(lg) {
		font-size: 30px;
		line-height: 40px;
		margin-bottom: 35px;
	}
}

.text--medium {
	font-size: 30px;
	line-height: 40px;
	font-weight: 500;
	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 35px;
	}
}

.text--large {
	font-size: 40px;
	line-height: 50px;
	font-weight: 500;
	@include media-breakpoint-down(lg) {
		font-size: 30px;
		line-height: 40px;
	}
}

.rte {
	margin-bottom: 112px;
	p {
		& + h4 {
			padding-top: 25px;
		}
		& + h5 {
			padding-top: 35px;
		}
	}
	ul {
		padding-left: 36px;
		margin-bottom: 45px;
		li {
			position: relative;
			margin-bottom: 10px;
			&::before {
				content: '';
				position: absolute;
				top: 12px;
				left: -24px;
				width: 3px;
				height: 3px;
				background-color: $tundora;
				border-radius: 50%;
			}
		}
	}
	ol {
		margin-bottom: 45px;
		li {
			list-style-type: decimal;
			padding-left: 12px;
			margin-left: 25px;
			margin-bottom: 10px;
		}
	}
	@include media-breakpoint-between(md, lg) {
		margin-bottom: 60px;
		h4 {
			margin-bottom: 5px;
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 75px;
	}
}
