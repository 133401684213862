//----------  custom scrollbar
//------------------------------------------------------------------------------

.scroll-wrapper {
	overflow: hidden;
	position: relative;
	& > .scroll-content {
		overflow: hidden;
		width: 100%;
		&::-webkit-scrollbar {
			height: 0;
			width: 0;
		}
	}
}

.scroll-element {
	display: none;
	&.scroll-y {
		&.scroll-scrolly_visible {
			display: block;
		}
	}
	.scroll-bar,
	.scroll-arrow {
		cursor: default;
	}
}

.scroll-textarea {
	& > .scroll-content {
		& > textarea {
			padding: 0 20px 0 0;
			box-sizing: content-box;
			&::-webkit-scrollbar {
				height: 0;
				width: 0;
			}
		}
	}
}

.scroll-wrapper {
	& > .scroll-element {
		position: absolute;
		z-index: 10;
		&.scroll-y {
			height: 100%;
			right: 0;
			top: 0;
			width: 14px;
		}
		div {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			display: block;
			z-index: 10;
		}
		.scroll-element_track {
			background-color: $merino;
		}
		.scroll-bar {
			background-color: $gold;
			min-height: 20px;
			@include hover {
				background-color: darken($gold, 10%);
			}
		}
	}
}
